import { default as indexH3tzStTfF7Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/about-company/index.vue?macro=true";
import { default as _91slug_932Y87Vx0P3UMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/actions/[slug].vue?macro=true";
import { default as index7fxu6MosYgMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/actions/index.vue?macro=true";
import { default as _91slug_935FHPI3u1gyMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/blog/[slug].vue?macro=true";
import { default as indexYnvhME5htJMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/blog/index.vue?macro=true";
import { default as _91slug_93n16CFFHkmlMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/bluda/[slug].vue?macro=true";
import { default as index3Ul7iCJ5aNMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/bluda/index.vue?macro=true";
import { default as statusWLZ8W4Xp0PMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/cabinet/payment/status.vue?macro=true";
import { default as indexgAU5nhl3BjMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/cart/index.vue?macro=true";
import { default as successKVxvg4Wx34Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/cart/success.vue?macro=true";
import { default as _91slug_931hgiYW2AU1Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/categories/[slug].vue?macro=true";
import { default as indexZ0b8b8PHs9Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/categories/index.vue?macro=true";
import { default as checking_45partnerseDY7Mfe3mjMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/checking-partners.vue?macro=true";
import { default as contactsbP3oDPtjZzMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/contacts.vue?macro=true";
import { default as index2FvaIMU5BgMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/delete-account/index.vue?macro=true";
import { default as index9A23ebD0EtMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/index.vue?macro=true";
import { default as indexHMN30YTNw9Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/info-for-partners/index.vue?macro=true";
import { default as _91slug_93YeNtYEMQXZMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/kuhni/[slug].vue?macro=true";
import { default as indexpif1zwKAH6Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/kuhni/index.vue?macro=true";
import { default as offerHJXl5nCSdQMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/offer.vue?macro=true";
import { default as indexF7ZA6DRPSoMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/partner-apply-from-mobile/index.vue?macro=true";
import { default as privacyilaEVE92jPMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/privacy.vue?macro=true";
import { default as addresesDFkZBveXrpMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/addreses.vue?macro=true";
import { default as indexoN2fcoctm8Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/index.vue?macro=true";
import { default as infodbPb4NJnWnMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/info.vue?macro=true";
import { default as reviewsjkQf7PAMe4Meta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/reviews.vue?macro=true";
import { default as _91slug_93qiTizmnrdUMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/restaurants/[slug].vue?macro=true";
import { default as indexYKmSPj7WkjMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/restaurants/index.vue?macro=true";
import { default as searchRDR4k7Bp6vMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/search.vue?macro=true";
import { default as standarts_45partnersV92QqxjnevMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/standarts-partners.vue?macro=true";
import { default as terms_45of_45useseTx43ciPrMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/terms-of-use.vue?macro=true";
import { default as indexVQn2fH4uWkMeta } from "/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/wish/index.vue?macro=true";
export default [
  {
    name: "about-company",
    path: "/about-company",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/about-company/index.vue").then(m => m.default || m)
  },
  {
    name: "actions-slug",
    path: "/actions/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/actions/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actions",
    path: "/actions",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "bluda-slug",
    path: "/bluda/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/bluda/[slug].vue").then(m => m.default || m)
  },
  {
    name: "bluda",
    path: "/bluda",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/bluda/index.vue").then(m => m.default || m)
  },
  {
    name: "cabinet-payment-status",
    path: "/cabinet/payment/status",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/cabinet/payment/status.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexgAU5nhl3BjMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "cart-success",
    path: "/cart/success",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/cart/success.vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "checking-partners",
    path: "/checking-partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/checking-partners.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "delete-account",
    path: "/delete-account",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/delete-account/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-for-partners",
    path: "/info-for-partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/info-for-partners/index.vue").then(m => m.default || m)
  },
  {
    name: "kuhni-slug",
    path: "/kuhni/:slug()",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/kuhni/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kuhni",
    path: "/kuhni",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/kuhni/index.vue").then(m => m.default || m)
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/offer.vue").then(m => m.default || m)
  },
  {
    name: "partner-apply-from-mobile",
    path: "/partner-apply-from-mobile",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/partner-apply-from-mobile/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-addreses",
    path: "/profile/addreses",
    meta: addresesDFkZBveXrpMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/addreses.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexoN2fcoctm8Meta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-info",
    path: "/profile/info",
    meta: infodbPb4NJnWnMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/info.vue").then(m => m.default || m)
  },
  {
    name: "profile-reviews",
    path: "/profile/reviews",
    meta: reviewsjkQf7PAMe4Meta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/profile/reviews.vue").then(m => m.default || m)
  },
  {
    name: "restaurants-slug",
    path: "/restaurants/:slug()",
    meta: _91slug_93qiTizmnrdUMeta || {},
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/restaurants/[slug].vue").then(m => m.default || m)
  },
  {
    name: "restaurants",
    path: "/restaurants",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/restaurants/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "standarts-partners",
    path: "/standarts-partners",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/standarts-partners.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "wish",
    path: "/wish",
    component: () => import("/var/www/sakhfood.dev.sitisit.ru/frontend/sakhfood-front-20241015015319/pages/wish/index.vue").then(m => m.default || m)
  }
]